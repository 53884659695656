import React, { Suspense } from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import SuspenseFallback from './cms-core/src/components/utils/SuspenseFallback';
import { LoggedUserContextProvider } from './cms-core/src/contexts/LoggedUserContext';
import authProvider from './cms-core/src/provider/authProvider';
import dataProvider from './cms-core/src/provider/dataProvider';
import i18nProvider from './cms-core/src/provider/i18nProvider';
import Log from './cms-core/src/resources/log';
import User from './cms-core/src/resources/user';
import { SquadProvider } from './contexts/SquadContext';
import ComingSoonPage from './layout/ComingSoonPage';
import CustomLayout from './layout/CustomLayout';
import Dashboard from './layout/Dashboard';
import Career from './resources/Career';
import Fifa from './resources/Fifa';
import Formation from './resources/Formation';
import Manager from './resources/Manager';
import Player from './resources/Player';
import Position from './resources/Position';
import Season from './resources/Season';
import Squad from './resources/Squad';
import Team from './resources/Team';
import Trophy from './resources/Trophy';
import theme from './theme';

// Lazy loading of components
const LoginPage = React.lazy(() => import('./cms-core/src/layout/LoginPage'));
const WrappedLoginPage: React.FC = () => (
  <LoginPage customImgSource="../images/fifa_main_logo.png" />
);
const SquadBuilder = React.lazy(() => import('./layout/SquadBuilder'));
const UnauthorizedPage = React.lazy(() => import('./cms-core/src/layout/UnauthorizedPage'));

// Main App
const App: React.FC = () => {
  const comingSoon = process.env.REACT_APP_COMING_SOON ?? '';
  return (
    <>
      {comingSoon.length > 0 && comingSoon === 'true' ? (
        <ComingSoonPage />
      ) : (
        <Suspense fallback={<SuspenseFallback />}>
          <LoggedUserContextProvider>
            <Admin
              dataProvider={dataProvider}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              layout={CustomLayout}
              loginPage={WrappedLoginPage}
              dashboard={Dashboard}
              theme={theme}
            >
              <Resource {...User} />
              <Resource {...Player} />
              <Resource {...Team} />
              <Resource {...Position} />
              <Resource {...Formation} />
              <Resource {...Squad} />
              <Resource {...Trophy} />
              <Resource {...Season} />
              <Resource {...Manager} />
              <Resource {...Career} />
              <Resource {...Fifa} />
              <Resource {...Log} />
              <Resource name="managed_season" />
              <Resource name="notable_player" />
              <Resource name="role" />
              <CustomRoutes>
                <Route
                  path="/squad-builder"
                  element={
                    <SquadProvider>
                      <SquadBuilder />
                    </SquadProvider>
                  }
                />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
              </CustomRoutes>
            </Admin>
          </LoggedUserContextProvider>
        </Suspense>
      )}
    </>
  );
};

export default App;
