import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  required,
  useRedirect,
  useTranslate,
  type CreateProps,
} from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import FifaIndexInput from '../../components/inputs/FifaIndexInput';
import FifaInput from '../../components/inputs/FifaInput';
import RivalTeamInput from '../../components/inputs/RivalTeamInput';
import CustomActions from '../../layout/CustomActions';
import { teamTransformData } from '../../utils';

const TeamCreate: React.FC<CreateProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  const redirect = useRedirect();
  const { isAdmin, loading } = useIsAdmin();
  const translate = useTranslate();

  React.useEffect(() => {
    if (!loading && !isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect, loading]);

  return (
    <Create
      redirect="show"
      actions={<CustomActions resource="team" hasList />}
      transform={teamTransformData}
      sx={{ marginBottom: '1rem' }}
      {...props}
    >
      <SimpleForm>
        <TextInput source="name" variant="filled" validate={required()} sx={{ width: 219 }} />
        <FifaInput
          source="fifaId"
          label={translate('resources.team.fields.fifaId')}
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
          hasMargin
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) =>
            formData.fifaId !== undefined && (
              <>
                <NumberInput
                  source="budget"
                  variant="filled"
                  validate={required()}
                  InputProps={{ inputProps: { min: 1 } }}
                  sx={{ width: 219 }}
                />
                <FifaIndexInput
                  source="fifaIndexId"
                  label={translate('resources.team.fields.fifaIndexId')}
                  hasMargin
                />
                <RivalTeamInput
                  source="rivalTeamId"
                  label={translate('resources.team.fields.rivalTeamId')}
                  view="create"
                  fifa={formData.fifaId}
                  hasMargin
                />
                <ReferenceInput
                  source="squadId"
                  reference="squad"
                  filter={{ fifaId: formData.fifaId }}
                >
                  <SelectInput
                    source="name"
                    variant="filled"
                    emptyText="Empty"
                    emptyValue="Empty"
                    defaultValue="Empty"
                    parse={(val: string) => (val === 'Empty' ? 'Empty' : val)}
                    sx={{
                      width: 219,
                    }}
                  />
                </ReferenceInput>
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default TeamCreate;
