import React from 'react';
import type { AxiosError } from 'axios';
import { useNotify, useLogout, useTranslate } from 'react-admin';
import { LoggedUserContext } from '../contexts/LoggedUserContext';
import type { AvailableLocale, LoggedUserContextType, UseIsAdminResult } from '../interfaces';
import { IsLogged } from '../service';
import { locales } from '../utils';

/**
 * Hook that determines if user is logged.
 *
 * @returns {boolean} Boolean value that indicates if user is logged.
 *
 * @example
 * const isLogged = useIsLogged();
 */
export const useIsLogged = (): boolean => {
  const [logged, setLogged] = React.useState<boolean>(true);
  const notify = useNotify();

  const getResult = async (): Promise<void> => {
    const data = await IsLogged();
    setLogged(data);
  };

  React.useEffect(() => {
    getResult().catch((err: AxiosError) => {
      if (err.response?.status === 500) notify('errors.serverError', { type: 'error' });
    });
  }, [notify]);

  return logged;
};

/**
 * Hook that displays notification based on API error.
 *
 * @param {boolean} isLoginPage - Boolean value that indicates if page is login page.
 *
 * @example
 * const handleError = useHandleApiError();
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useHandleApiError = (isLoginPage?: boolean) => {
  const notify = useNotify();
  const logout = useLogout();

  return React.useCallback(
    async (err: AxiosError) => {
      const errObj = {
        status: err.response?.status ?? 500,
        msg: err.response?.data ?? 'Server Error',
      };
      console.error(`Error: ${JSON.stringify(errObj)}`);
      if (err.response?.status === 400) notify('errors.invalidData', { type: 'error' });
      if (err.response?.status === 401 && isLoginPage === undefined) await logout();
      if (err.response?.status === 401 && isLoginPage !== undefined) {
        notify('errors.wrongCredentials', { type: 'error' });
      }
      if (err.response?.status === 403) notify('errors.insufficientPermissions', { type: 'error' });
      if (err.response?.status === 404) notify('errors.notFound', { type: 'error' });
      if (err.response?.status === 409) notify('errors.duplicateEntity', { type: 'error' });
      if (err.response?.status === 500) notify('errors.serverError', { type: 'error' });
    },
    [notify, logout, isLoginPage],
  );
};

/**
 * Hook that gets available locales.
 *
 * @returns {AvailableLocale[]} Array with available locales.
 *
 * @example
 * const locales = useGetAvailableLocales();
 */
export const useGetAvailableLocales = (): AvailableLocale[] => {
  const localesEnv = process.env.REACT_APP_LOCALES ?? '';
  if (localesEnv.length > 0) {
    const localesToGet = localesEnv.split(',');
    const availableLocales = locales.filter((locale: AvailableLocale) =>
      localesToGet.some((name: string) => locale.locale === name),
    );
    return availableLocales;
  } else return [];
};

/**
 * Hook that gets logged user context from store.
 *
 * @returns {LoggedUserContextType} Object with user methods.
 *
 * @example
 * const loggedUser = useLoggedUser();
 */
export const useLoggedUser = (): LoggedUserContextType => {
  const translate = useTranslate();
  const context = React.useContext(LoggedUserContext);
  if (!context) {
    throw new Error(translate('errors.loggedUserContext'));
  }
  return context;
};

/**
 * Hook that returns if logged user has admin permission
 *
 * @returns {boolean} Indicates if user is admin.
 *
 * @example
 * const isAdmin = useIsAdmin();
 */
export const useIsAdmin = (): UseIsAdminResult => {
  const { user, loading } = useLoggedUser();
  return { isAdmin: user?.role_id === 1, loading };
};

/**
 * Hook taht returns shortened translated page title.
 *
 * @param {string} translationId - ID of string from langs.
 * @returns {string} Translated shortened title.
 *
 * @example
 * const title = useRenderTitle('errors.insufficientPermissions');
 */
export const useRenderTitle = (translationId: string): string => {
  const translate = useTranslate();
  const fullTitle = translate(translationId);
  return `${fullTitle.substring(0, 10)} ...`;
};
