import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useController } from 'react-hook-form';
import type { FifaIndexInputProps } from '../../interfaces';

const FifaIndexInput: React.FC<FifaIndexInputProps> = ({ source, label, hasMargin }) => {
  const input = useController({ name: source }).field;
  const [value, setValue] = React.useState<string>('');

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    input.onChange(parseInt(event.target.value));
  };

  const handleScouted = (): void => {
    setValue('99999999');
    input.onChange(parseInt('99999999'));
  };

  return (
    <Box sx={{ marginBottom: hasMargin ? '1.5rem' : 0 }}>
      <FormControl
        sx={{
          width: 280,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <TextField
            type="number"
            label={`${label} *`}
            value={value}
            variant="filled"
            onChange={handleInput}
            InputProps={{ inputProps: { min: 1 } }}
            sx={{
              width: 219,
              marginTop: '4px',
            }}
          />
          <Tooltip title="Scouted">
            <IconButton
              color="primary"
              onClick={handleScouted}
              size="small"
              sx={{
                marginLeft: '5px',
              }}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </FormControl>
    </Box>
  );
};

export default FifaIndexInput;
