import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, useRedirect, type EditProps } from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import CustomTitle from '../../cms-core/src/layout/CustomTitle';
import type { Formation, FormationErrors } from '../../interfaces';
import CustomActions from '../../layout/CustomActions';

const FormationEdit: React.FC<EditProps> = (props) => {
  const { isAdmin, loading } = useIsAdmin();
  const redirect = useRedirect();

  const validateForm = (values: Partial<Formation>): FormationErrors => {
    const errors: FormationErrors = {};
    const { name, defenders, midfielders, attackers, layout } = values;
    if (defenders !== null && midfielders !== null && attackers !== null) {
      if (defenders !== undefined && midfielders !== undefined && attackers !== undefined) {
        const totalPlayers: number = defenders + midfielders + attackers;
        if (totalPlayers !== 10) {
          errors.defenders = 'Total amount of players is incorrect.';
          errors.midfielders = 'Total amount of players is incorrect.';
          errors.attackers = 'Total amount of players is incorrect.';
        }
      }
    }
    if (name === null) errors.name = 'Required';
    if (layout === null) errors.layout = 'Required';
    if (defenders === null) errors.defenders = 'Required';
    if (midfielders === null) errors.midfielders = 'Required';
    if (attackers === null) errors.attackers = 'Required';
    return errors;
  };

  React.useEffect(() => {
    if (!loading && !isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect, loading]);

  return (
    <Edit
      redirect="show"
      title={<CustomTitle resource="formation" source="name" view="show" />}
      actions={<CustomActions resource="formation" hasShow hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm validate={validateForm}>
        <TextInput source="name" variant="filled" sx={{ width: 219 }} />
        <NumberInput
          source="defenders"
          variant="filled"
          InputProps={{ inputProps: { min: 0 } }}
          sx={{ width: 219 }}
        />
        <NumberInput
          source="midfielders"
          variant="filled"
          InputProps={{ inputProps: { min: 0 } }}
          sx={{ width: 219 }}
        />
        <NumberInput
          source="attackers"
          variant="filled"
          InputProps={{ inputProps: { min: 0 } }}
          sx={{ width: 219 }}
        />
        <TextInput source="layout" variant="filled" helperText="E.g. 4-1-2-3" sx={{ width: 219 }} />
      </SimpleForm>
    </Edit>
  );
};

export default FormationEdit;
