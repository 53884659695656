import React, { createContext, useState } from 'react';
import type { AxiosError } from 'axios';
import type { LoggedUser, LoggedUserContextType, LoggedUserProvider } from '../interfaces';
import { GetLoggedUser } from '../service';

export const LoggedUserContext = createContext<LoggedUserContextType | null>(null);

/**
 * Context provider to get data of logged user.
 *
 * @prop {React.ReactNode} children - The app component to be wrapped by this context provider.
 */
export const LoggedUserContextProvider: React.FC<LoggedUserProvider> = ({ children }) => {
  const [user, setUser] = useState<LoggedUser | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const login = (login: string, roleId: number): void => {
    setUser({ login, role_id: roleId });
  };

  const logout = (): void => {
    setUser(null);
  };

  // Hydrate context data using localStorage
  const initializeUserFromLocalStorage = React.useCallback(async () => {
    const login = localStorage.getItem('username');
    if (login && !user) {
      const userData = await GetLoggedUser();
      setUser({ login: userData.login, role_id: userData.role_id });
      setLoading(false);
    }
  }, [user]);

  React.useEffect(() => {
    initializeUserFromLocalStorage().catch(async (err: AxiosError) => {
      const errObj = {
        status: err.response?.status ?? 500,
        msg: err.response?.data ?? 'Server Error',
      };
      console.error(`Error: ${JSON.stringify(errObj)}`);
    });
  }, [initializeUserFromLocalStorage]);

  return (
    <LoggedUserContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </LoggedUserContext.Provider>
  );
};
