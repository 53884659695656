import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, useRedirect, useTranslate } from 'react-admin';
import type { CustomCreateButtonProps } from '../../interfaces';

const ImportDialog = React.lazy(() => import('../utils/ImportDialog'));

const CustomCreateButton: React.FC<CustomCreateButtonProps> = ({ resource, hasMenu }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    if (hasMenu) setAnchorEl(event.currentTarget);
    else redirect('create', resource);
  };

  return (
    <>
      <Button color="primary" size="small" onClick={handleOpen} label="ra.action.create">
        <AddIcon />
      </Button>
      {openMenu ? (
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={() => redirect('create', resource)}>
            {translate('ra.action.create')}
          </MenuItem>
          <MenuItem onClick={() => setOpenDialog(true)}>{translate('actions.csvImport')}</MenuItem>
        </Menu>
      ) : null}
      {openDialog ? (
        <ImportDialog open={openDialog} setOpen={setOpenDialog} resource={resource} />
      ) : null}
    </>
  );
};

export default CustomCreateButton;
