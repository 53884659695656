import React, { type SyntheticEvent } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import type { AxiosError } from 'axios';
import { useRecordContext, useDataProvider } from 'react-admin';
import { useController } from 'react-hook-form';
import { useHandleApiError } from '../../cms-core/src/hooks';
import type { RivalTeamInputProps, Team } from '../../interfaces';
import SmallLoader from '../utils/SmallLoader';

const RivalTeamInput: React.FC<RivalTeamInputProps> = ({
  source,
  label,
  view,
  fifa,
  hasMargin,
}) => {
  const input = useController({ name: source }).field;
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [choices, setChoices] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedTeamName, setSelectedTeamName] = React.useState<string>('Empty');

  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();
  const record = useRecordContext() ?? { id: 0 };

  const handleInput = (event: SyntheticEvent, newValue: string | null): void => {
    if (newValue !== null) {
      setSelectedTeamName(newValue);
      const filtered = teams.filter((team: Team) => team.name === newValue);
      input.onChange(filtered.length === 0 ? null : filtered[0].id);
    }
  };

  const getTeams = React.useCallback(async () => {
    const teams = await dataProvider.getList('team', {
      pagination: { page: 1, perPage: 10000000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });

    const filterDataByView = (teams: Team[]): Team[] => {
      if (view === 'edit') {
        const filtered = teams.filter((team: Team) => team.id !== record.id);
        const filteredFifa = filtered.filter((team: Team) => team.fifaId === fifa);
        return filteredFifa;
      } else {
        if (fifa !== undefined) {
          const filtered = teams.filter((team: Team) => team.fifaId === fifa);
          return filtered;
        } else return teams;
      }
    };

    const filtered = filterDataByView(teams.data);
    setTeams(filtered);
    const onlyNames = filtered.map((team: Team) => team.name);
    const withEmpty = [...onlyNames, 'Empty'];
    setChoices(withEmpty);
    if (input.value !== undefined || input.value !== null) {
      const currentTeam = filtered.filter((team: Team) => team.id === input.value);
      if (currentTeam.length > 0) setSelectedTeamName(currentTeam[0].name);
    }
  }, [dataProvider, fifa, input.value, record.id, view]);

  React.useEffect(() => {
    getTeams()
      .catch(async (err: AxiosError) => {
        await handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getTeams, handleError]);

  return !loading ? (
    <Box sx={{ marginBottom: hasMargin ? '1.5rem' : 0 }}>
      <FormControl
        sx={{
          width: 219,
        }}
      >
        <Autocomplete
          value={selectedTeamName}
          onChange={handleInput}
          options={choices}
          renderInput={(params) => <TextField {...params} label={label} variant="filled" />}
        />
      </FormControl>
    </Box>
  ) : (
    <SmallLoader />
  );
};

export default RivalTeamInput;
