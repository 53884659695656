import { defaultTheme } from 'react-admin';

const theme = {
  palette: {
    primary: {
      main: '#00b300',
      contrastText: '#e6ffe6',
    },
    secondary: {
      main: '#fff',
    },
    background: {
      default: '#F4F4F4',
    },
  },
  components: {
    ...defaultTheme.components,
    RaSaveButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Gilroy',
          color: '#fff',
        },
      },
    },
    RaCreateButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Gilroy',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Gilroy',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Gilroy',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Gilroy',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Gilroy',
        },
      },
    },
  },
};

export default theme;
