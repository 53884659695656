import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import { Button, useRecordContext, useNotify } from 'react-admin';
import { useHandleApiError } from '../../cms-core/src/hooks';
import { HandleFavourite } from '../../services';

const FavouriteButton: React.FC = () => {
  const [isFavourite, setIsFavourite] = React.useState<boolean>(false);
  const record = useRecordContext();
  const notify = useNotify();
  const handleError = useHandleApiError();

  const handleFavourite = async (isFavourite: number): Promise<void> => {
    const playerId = get(record, ['id'], 0);
    HandleFavourite(playerId, isFavourite);
    if (isFavourite) {
      notify('success.favouriteAdded', { type: 'success' });
      setIsFavourite(true);
    } else {
      notify('success.favouriteRemoved', { type: 'success' });
      setIsFavourite(false);
    }
  };

  const handleAdd = (): void => {
    handleFavourite(1).catch(async (err: AxiosError) => {
      await handleError(err);
    });
  };

  const handleRemove = (): void => {
    handleFavourite(0).catch(async (err: AxiosError) => {
      await handleError(err);
    });
  };

  React.useEffect(() => {
    if (record !== undefined) {
      const favourite = get(record, ['is_favourite'], 0);
      setIsFavourite(favourite > 0);
    }
  }, [record]);

  return (
    <Button
      color="primary"
      size="small"
      label={isFavourite ? 'actions.removeFromFavourites' : 'actions.addToFavourites'}
      onClick={isFavourite ? handleRemove : handleAdd}
    >
      {isFavourite ? <StarIcon /> : <StarBorderIcon />}
    </Button>
  );
};

export default FavouriteButton;
