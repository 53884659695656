import React from 'react';
import type { AxiosError } from 'axios';
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  FormDataConsumer,
  required,
  useDataProvider,
  useRedirect,
  type CreateProps,
  type RaRecord,
} from 'react-admin';
import { useIsAdmin, useHandleApiError } from '../../cms-core/src/hooks';
import FifaInput from '../../components/inputs/FifaInput';
import CustomActions from '../../layout/CustomActions';

const CareerCreate: React.FC<CreateProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  const [managers, setManagers] = React.useState<RaRecord[]>([]);
  const [managersCopy, setManagersCopy] = React.useState<RaRecord[]>([]);

  const { isAdmin, loading } = useIsAdmin();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();

  const getManagers = React.useCallback(async () => {
    const data = await dataProvider.getList('manager', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });
    setManagers(data.data);
    setManagersCopy(data.data);
  }, [dataProvider]);

  React.useEffect(() => {
    getManagers().catch(async (err: AxiosError) => {
      await handleError(err);
    });
  }, [getManagers, handleError]);

  React.useEffect(() => {
    if (parseInt(selectedVal) > 0) {
      const filtered = managersCopy.filter(
        (manager: RaRecord) => manager.fifaId === parseInt(selectedVal),
      );
      setManagers(filtered);
    }
  }, [selectedVal, managersCopy]);

  React.useEffect(() => {
    if (!loading && !isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect, loading]);

  return (
    <Create
      redirect="show"
      actions={<CustomActions resource="career" hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm>
        <TextInput source="name" variant="filled" validate={required()} sx={{ width: 219 }} />
        <FifaInput
          source="fifaId"
          label="FIFA"
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
          hasMargin
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) =>
            formData.fifaId !== undefined && (
              <AutocompleteInput
                source="managerId"
                choices={managers}
                variant="filled"
                validate={required()}
                optionText={(record: RaRecord) => `${record.firstName} ${record.lastName}`}
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 2;
                }}
                noOptionsText="Type 3 characters"
                sx={{
                  width: '219px !important',
                }}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default CareerCreate;
