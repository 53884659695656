import React from 'react';
import { Edit, SimpleForm, TextInput, required, useRedirect, type EditProps } from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import CustomActions from '../../layout/CustomActions';
import ManagerTitle from '../../layout/ManagerTitle';

const ManagerEdit: React.FC<EditProps> = (props) => {
  const { isAdmin, loading } = useIsAdmin();
  const redirect = useRedirect();

  React.useEffect(() => {
    if (!loading && !isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect, loading]);

  return (
    <Edit
      redirect="show"
      title={<ManagerTitle view="edit" />}
      actions={<CustomActions resource="manager" hasShow hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm>
        <TextInput source="firstName" variant="filled" validate={required()} sx={{ width: 219 }} />
        <TextInput source="lastName" variant="filled" validate={required()} sx={{ width: 219 }} />
      </SimpleForm>
    </Edit>
  );
};

export default ManagerEdit;
