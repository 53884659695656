import React from 'react';
import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { AxiosError } from 'axios';
import { Title, useTranslate, type RaRecord } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useHandleApiError, useLoggedUser } from '../cms-core/src/hooks';
import LatestPlayersList from '../components/utils/LatestPlayersList';
import SmallLoader from '../components/utils/SmallLoader';
import TopRatedPlayersList from '../components/utils/TopRatedPlayersList';
import { GetLatestPlayers, GetTopRatedPlayers, GetLoggedUser } from '../services';

const Dashboard: React.FC = () => {
  const [latestPlayers, setLatestPlayers] = React.useState<RaRecord[]>([]);
  const [topRatedPlayers, setTopRatedPlayers] = React.useState<RaRecord[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingTopRated, setLoadingTopRated] = React.useState<boolean>(true);

  const navigate = useNavigate();
  const handleError = useHandleApiError();
  const { user, login } = useLoggedUser();
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getLoggedUserData = React.useCallback(async () => {
    const userData = await GetLoggedUser();
    login(userData.login, userData.role_id);
  }, [login]);

  const getLatestPlayers = React.useCallback(async () => {
    const data = await GetLatestPlayers();
    setLatestPlayers(data);
  }, []);

  const getTopRatedPlayers = React.useCallback(async () => {
    const data = await GetTopRatedPlayers();
    setTopRatedPlayers(data);
  }, []);

  React.useEffect(() => {
    getLatestPlayers()
      .catch(async (err: AxiosError) => {
        await handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });

    getTopRatedPlayers()
      .catch(async (err: AxiosError) => {
        await handleError(err);
      })
      .finally(() => {
        setLoadingTopRated(false);
      });
  }, [getLatestPlayers, getTopRatedPlayers, handleError]);

  React.useEffect(() => {
    if (!user) {
      getLoggedUserData().catch((err: AxiosError) => {
        handleError(err);
      });
    }
  }, [user, handleError, getLoggedUserData]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card
            raised
            sx={{
              margin: '1rem auto 0px',
              width: '95%',
              height: '150px',
              position: 'relative',
            }}
          >
            <Title title="Fifa Squad Builder" />
            <CardContent>
              <Typography variant="h5">{translate('pages.dashboard.title')}</Typography>
            </CardContent>
            <CardActions sx={{ bottom: 5, position: 'absolute', display: 'flex' }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => navigate('/squad-builder')}
                startIcon={<BuildIcon />}
                sx={{
                  color: '#fff',
                }}
              >
                {translate('pages.dashboard.subtitle')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card sx={{ margin: '1rem auto 0px', width: '95%', height: '300px' }} raised>
            <CardContent>
              <Typography variant="h5">{translate('pages.dashboard.newestPlayers')}</Typography>
              {!loading ? <LatestPlayersList players={latestPlayers} /> : <SmallLoader />}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card
            sx={{
              margin: `1rem auto ${isSmall ? '1rem' : '0px'}`,
              width: '95%',
              height: '300px',
            }}
            raised
          >
            <CardContent>
              <Typography variant="h5">{translate('pages.dashboard.topRated')}</Typography>
              {!loadingTopRated ? (
                <TopRatedPlayersList players={topRatedPlayers} />
              ) : (
                <SmallLoader />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
