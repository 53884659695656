import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useRedirect,
  type EditProps,
} from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import CustomTitle from '../../cms-core/src/layout/CustomTitle';
import CustomActions from '../../layout/CustomActions';

const SquadEdit: React.FC<EditProps> = (props) => {
  const redirect = useRedirect();
  const { isAdmin, loading } = useIsAdmin();

  React.useEffect(() => {
    if (!loading && !isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect, loading]);

  return (
    <Edit
      redirect="show"
      title={<CustomTitle resource="squad" source="name" view="edit" />}
      actions={<CustomActions resource="squad" hasShow hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm>
        <TextInput source="name" variant="filled" validate={required()} sx={{ width: 219 }} />
        <ReferenceInput source="formationId" reference="formation">
          <SelectInput
            source="name"
            variant="filled"
            optionText="name"
            optionValue="id"
            validate={required()}
            sx={{
              width: 219,
            }}
            disabled
          />
        </ReferenceInput>
        <ReferenceInput source="fifaId" reference="fifa">
          <SelectInput
            label="FIFA"
            variant="filled"
            validate={required()}
            optionText="name"
            optionValue="id"
            disabled
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default SquadEdit;
