import React from 'react';
import {
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  required,
  useRedirect,
  type CreateProps,
  type RaRecord,
} from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import SeasonInput from '../../components/inputs/SeasonInput';
import type { CreateSeasonFormErrors } from '../../interfaces';
import CustomActions from '../../layout/CustomActions';
import { isValidYearFormat } from '../../utils';

const SeasonCreate: React.FC<CreateProps> = (props) => {
  const redirect = useRedirect();
  const { isAdmin, loading } = useIsAdmin();

  const validateForm = (data: Partial<RaRecord>): CreateSeasonFormErrors => {
    const errors: CreateSeasonFormErrors = {};
    if (data.name === undefined || data.name.length !== 9) errors.name = 'Required';
    if (data.fifaId === undefined) errors.fifaId = 'Required';
    if (!isValidYearFormat(data.name)) errors.name = 'Required';
    return errors;
  };

  React.useEffect(() => {
    if (!loading && !isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect, loading]);

  return (
    <Create
      redirect="list"
      actions={<CustomActions resource="season" hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm validate={validateForm}>
        <SeasonInput source="name" />
        <ReferenceInput source="fifaId" reference="fifa" sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput
            label="FIFA"
            variant="filled"
            validate={required()}
            optionText="name"
            optionValue="id"
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default SeasonCreate;
